@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: 'Aeonik Pro';
        src: url('https://assets.atomic.radio/web/fonts/aeonikpro/regular.woff2') format('woff2');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'Aeonik Pro';
        src: url('https://assets.atomic.radio/web/fonts/aeonikpro/bold.woff2') format('woff2');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }
    html {
        font-family: 'Aeonik Pro';
    }
}